const awsmobile = {
  "aws_project_region": "us-west-2",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_QXkqgwCpu",
  "aws_user_pools_web_client_id": "5td7tg19sionpqmfln809n1ld",
  "oauth": {
    "domain": "baymanageridp.fullswingapps.com",
    "scope": [
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://stgbaymanageradmin.fullswingapps.com",
    "redirectSignOut": "https://stgbaymanageradmin.fullswingapps.com/login",
    "responseType": "code"
  }
};

export default awsmobile;
