// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {DefaultPrivacyLevel} from "@datadog/browser-rum";

enum Site {
  US1 = 'datadoghq.com'
}

type ConsoleApiName = 'log' | 'debug' | 'info' | 'warn' | 'error';

export const environment = {
  version: '1.1.0', // This is the version of the application
  production: true,
  resourceServerURL: 'http://localhost:8081',
  apiServer: 'https://stglauncher.fullswingapps.com/api/fs',
  awsAPIURL: 'https://k0zmbfs553.execute-api.us-west-2.amazonaws.com/staging',
  hostName: 'stgbaymanageradmin.fullswingapps.com',
  appConfigPath: './assets/config/app-config.json',
  defaultPage: 'event',
  //Local strorage values
  navName: '_fs_nav_name',


  datadog: {
    enableDatadogLogs: true,
    enableDatadogRum: true,
    rumConfig: {
      applicationId: '035e022c-87a6-41ba-b8e5-af6d31fde602',
      clientToken: 'pubf13fb6ce0f08ea56d75f8c2b1df2f4ee',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: Site.US1,
      service: 'admin-utility-website',
      env: 'staging',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.1.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      allowedTracingUrls: ["https://stglauncher.fullswingapps.com"]
    },
    // make the forwardConsoleLogs only to "warn" using the ConsoleApiName[] required


    logsConfig: {
      clientToken: 'pubf13fb6ce0f08ea56d75f8c2b1df2f4ee',
      site: Site.US1,
      service: 'admin-utility-website',
      env: 'staging',
      version: '1.1.0',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: [ 'warn', 'error'] as ConsoleApiName[],
      sessionSampleRate: 100,
    }
  }
};

// export const environment = {
//   production: true,
//   resourceServerURL: 'http://localhost:8081',
//   apiServer: 'https://devlauncher.fsgapi.net/api/fs',
//   awsAPIURL: 'https://32zlw20y67.execute-api.us-west-2.amazonaws.com/dev/bay-manager-explorer',
//   hostName: 'devbaymanageradmin.fsgapi.net',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
