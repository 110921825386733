import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TableConfigModel } from 'src/app/shared/models/Application.model';
import { PageOptions } from 'src/app/shared/models/page-options.model';
import {
  CreateUserRequest,
  CreateUserResponse,
  DeleteUserRequest,
  ListUserResponse,
  ResetPasswordRequest,
  UpdateUserRequest,
  UpdateUserResponse,
  UserResponse,
  UserResponseView,
} from 'src/app/shared/models/users.model';
import { AppConfigService } from 'src/app/shared/services/app-config.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersService {
  private _pageIcon: any;
  public get getPageIcon(): any {
    return this._pageIcon;
  }
  public set setPageIcon(icon: any) {
    this._pageIcon = icon;
  }

  private _table: TableConfigModel;
  public get getTableConf(): TableConfigModel {
    return this._table;
  }

  private _svg: string[];
  public get getSVGs(): string[] {
    return this._svg;
  }

  constructor(
    private httpClient: HttpClient,
    private appConfService: AppConfigService
  ) {
    this._table = this.appConfService.get('elements')?.table;
    this._svg = this.appConfService.get('elements')?.svg;
    this._pageIcon = this.appConfService.get()?.elements;
  }

  public getAllUsers(data: PageOptions): Observable<ListUserResponse> {
    const url =
      environment.apiServer +
      `/Users?page_number=${data.page_number}&page_size=${data.page_size}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<ListUserResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getUserByCognitoId(cognito_id: any): Observable<UserResponse> {
    const url = environment.apiServer + `/Users/${cognito_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<UserResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getUserById(id: number): Observable<UserResponse> {
    const url = environment.apiServer + `/Users/bma/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<UserResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getUsersView(): Observable<UserResponseView[]> {
    const url = environment.apiServer + `/Users/GetUsersView`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<UserResponseView[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public createUser(data: CreateUserRequest): Observable<CreateUserResponse> {
    const url = environment.apiServer + `/Users`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<CreateUserResponse>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public updateUser(data: UpdateUserRequest): Observable<UpdateUserResponse> {
    const url = environment.apiServer + `/Users`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .put<UpdateUserResponse>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public deleteUser(data: DeleteUserRequest): Observable<HttpStatusCode> {
    const url = environment.apiServer + `/Users`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .delete<HttpStatusCode>(url, { headers: headers, body: data })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public resetForgotPassword(data: ResetPasswordRequest): Observable<boolean> {
    const url = environment.apiServer + `/Users/ResetForgotPassword`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<boolean>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }
}
