import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TableConfigModel } from 'src/app/shared/models/Application.model';
import {
  CompanyDropDownList,
  CompanyResponse,
  CompanyResponseView,
  CreateCompanyRequest,
  CreateCompanyResponse,
  DeleteCompanyRequest,
  ListCompanyResponse,
  UpdateCompanyRequest,
} from 'src/app/shared/models/companies.model';
import { CompanyType } from 'src/app/shared/models/enums';
import {
  CreateLicenseRequset,
  DeleteLicenseRequest,
  LicenseResponse,
  ListLicenseResponse,
  UpdateLicenseRequest,
} from 'src/app/shared/models/license.model';
import {
  CreateLocationRequest,
  CreateLocationResponse,
  DeleteLocationRequest,
  ListLocationResponse,
  LocationDropDownList,
  LocationResponse,
  LocationResponseView,
  LocationTempList,
  UpdateLocationRequest,
} from 'src/app/shared/models/location.model';
import { PageOptions } from 'src/app/shared/models/page-options.model';
import {
  CreateSimulatorRequest,
  CreateSimulatorResponse,
  DeleteSimulatorRequest,
  ListSimulatorResponse,
  SimulatorResponse,
  SimulatorResponseView,
  UpdateSimulatorRequest,
  UpdateSimulatorResponse,
} from 'src/app/shared/models/simulators.model';
import {
  CreateUserRequest,
  CreateUserResponse,
  DeleteUserRequest,
  UpdateUserRequest,
  UpdateUserResponse,
  UserResponse,
  UserResponseView,
} from 'src/app/shared/models/users.model';
import { AppConfigService } from 'src/app/shared/services/app-config.service';
import { HelperService } from 'src/app/shared/services/Helper.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CompaniesService {
  //private _tokenId: string;

  private _locationTempList: LocationTempList[];
  public get getLocationTempList(): LocationTempList[] {
    return this._locationTempList;
  }
  public set setLocationToTempList(name: LocationTempList) {
    this._locationTempList.push(name);
  }

  public set setTokenId(value: LocationTempList) {
    //this._tokenId = value;
  }

  private _pageIcon: any;
  public get getPageIcon(): any {
    return this._pageIcon;
  }
  public set setPageIcon(icon: any) {
    this._pageIcon = icon;
  }

  private _table: TableConfigModel;
  public get getTableConf(): TableConfigModel {
    return this._table;
  }

  private _svg: string[];
  public get getSVGs(): string[] {
    return this._svg;
  }

  private _companyType: CompanyType;
  public get getCompanyType(): CompanyType {
    return this._companyType;
  }
  public set setCompanyType(type: CompanyType) {
    this._companyType = type;
  }

  private _strCompanyType: string;
  public get getStrCompanyType(): string {
    return this._strCompanyType;
  }
  public set setStrCompanyType(type: string) {
    this._strCompanyType = type;
  }

  constructor(
    private httpClient: HttpClient,
    private appConfService: AppConfigService,
    private helperService: HelperService
  ) {
    this._locationTempList = new Array<LocationTempList>();
    this._table = this.appConfService.get('elements')?.table;
    this._svg = this.appConfService.get('elements')?.svg;
    this._pageIcon = this.appConfService.get()?.elements;
  }

  public getCompanyTypeFromString(type: string): CompanyType {
    return CompanyType['' + this.helperService.capitalizeWord(type)];
  }

  public getAllCompanies(data: PageOptions): Observable<ListCompanyResponse> {
    const url =
      environment.apiServer +
      `/Companies?page_number=${data.page_number}&page_size=${data.page_size}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .get<ListCompanyResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getCompany(id: number): Observable<CompanyResponse> {
    const url = environment.apiServer + `/Companies/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    ///headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .get<CompanyResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getCompaniesDropDownList(): Observable<CompanyDropDownList[]> {
    const url = environment.apiServer + `/Companies/GetCompaniesDropDownList`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<CompanyDropDownList[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getCompaniesView(
    companyType: CompanyType
  ): Observable<CompanyResponseView[]> {
    const url =
      environment.apiServer + `/Companies/GetCompaniesView/${companyType}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<CompanyResponseView[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public createCompany(
    data: CreateCompanyRequest
  ): Observable<CreateCompanyResponse> {
    const url = environment.apiServer + `/Companies`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .post<CreateCompanyResponse>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public updateCompany(data: UpdateCompanyRequest): Observable<boolean> {
    const url = environment.apiServer + `/Companies`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .put<boolean>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public deleteCompany(data: DeleteCompanyRequest): Observable<HttpStatusCode> {
    const url = environment.apiServer + `/Companies`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .delete<HttpStatusCode>(url, { headers: headers, body: data })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  //-----------Location services--------------------

  public getAllLocations(data: PageOptions): Observable<ListLocationResponse> {
    const url =
      environment.apiServer +
      `/Locations?page_number=${data.page_number}&page_size=${data.page_size}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .get<ListLocationResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getLocation(id: number): Observable<LocationResponse> {
    const url = environment.apiServer + `/Locations/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .get<LocationResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getLocationsDropDownList(
    companyId: number
  ): Observable<LocationDropDownList[]> {
    const url =
      environment.apiServer +
      `/Locations/GetLocationsDropDownList/${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .get<LocationDropDownList[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public createLocation(
    data: CreateLocationRequest
  ): Observable<CreateLocationResponse> {
    const url = environment.apiServer + `/Locations`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .post<CreateLocationResponse>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public updateLocation(data: UpdateLocationRequest): Observable<number> {
    const url = environment.apiServer + `/Locations`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .put<number>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public deleteLocation(
    data: DeleteLocationRequest
  ): Observable<HttpStatusCode> {
    const url = environment.apiServer + `/Locations`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .delete<HttpStatusCode>(url, { headers: headers, body: data })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  //-----------Licenses services--------------------

  public getAllLicense(
    data: PageOptions,
    companyId: number
  ): Observable<ListLicenseResponse> {
    const url =
      environment.apiServer +
      `/Licenses?page_number=${data.page_number}&page_size=${data.page_size}&company_id=${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<ListLicenseResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getLicense(id: number): Observable<LicenseResponse> {
    const url = environment.apiServer + `/Licenses/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<LicenseResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public createLicense(data: CreateLicenseRequset): Observable<number> {
    const url = environment.apiServer + `/Licenses`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<number>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public updateLicense(data: UpdateLicenseRequest): Observable<number> {
    const url = environment.apiServer + `/Licenses`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .put<number>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public deleteLicense(data: DeleteLicenseRequest): Observable<number> {
    const url = environment.apiServer + `/Licenses`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .delete<number>(url, { headers: headers, body: data })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  //-----------Location services--------------------

  public getAllSimulators(
    data: PageOptions
  ): Observable<ListSimulatorResponse> {
    const url =
      environment.apiServer +
      `/Simulator?page_number=${data.page_number}&page_size=${data.page_size}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<ListSimulatorResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getSimulator(id: number): Observable<SimulatorResponse> {
    const url = environment.apiServer + `/Simulator/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<SimulatorResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getSimulatorsByCompanyId(
    companyId: number
  ): Observable<SimulatorResponse[]> {
    const url =
      environment.apiServer +
      `/Simulator/GetSimulatorsByCompanyId/${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    ///headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .get<SimulatorResponse[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getSimulatorsByCompanyId_2(
    companyId: number
  ): Observable<SimulatorResponseView[]> {
    const url =
      environment.apiServer +
      `/Simulator/GetSimulatorsByCompanyId_2/${companyId}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    ///headers = headers.set('Authorization', `Bearer ${this._tokenId}`);

    return this.httpClient
      .get<SimulatorResponseView[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public createSimulator(
    data: CreateSimulatorRequest
  ): Observable<CreateSimulatorResponse> {
    const url = environment.apiServer + `/Simulator`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<CreateSimulatorResponse>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public updateSimulator(
    data: UpdateSimulatorRequest
  ): Observable<UpdateSimulatorResponse> {
    const url = environment.apiServer + `/Simulator`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .put<UpdateSimulatorResponse>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public deleteSimulator(data: DeleteSimulatorRequest): Observable<number> {
    const url = environment.apiServer + `/Simulator`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .delete<number>(url, { headers: headers, body: data })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getUsersByCompanyId(
    company_id: number
  ): Observable<UserResponseView[]> {
    const url =
      environment.apiServer + `/Users/GetUsersByCompanyId/${company_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<UserResponseView[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getUserById(id: number): Observable<UserResponse> {
    const url = environment.apiServer + `/Users/bma/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<UserResponse>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getLocationsByCompanyId(
    company_id: number
  ): Observable<LocationResponseView[]> {
    const url =
      environment.apiServer +
      `/Locations/GetLocationsByCompanyId/${company_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<LocationResponseView[]>(url, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public deleteUser(data: DeleteUserRequest): Observable<HttpStatusCode> {
    const url = environment.apiServer + `/Users`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .delete<HttpStatusCode>(url, { headers: headers, body: data })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public createUser(data: CreateUserRequest): Observable<CreateUserResponse> {
    const url = environment.apiServer + `/Users`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<CreateUserResponse>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public updateUser(data: UpdateUserRequest): Observable<UpdateUserResponse> {
    const url = environment.apiServer + `/Users`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .put<UpdateUserResponse>(url, data, { headers: headers })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }
}
