import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';

import { HttpInterceptorProviders } from './shared/InterceptorProvider';
import { AuthGuard } from './shared/Auth.guard';
import { ContentComponent } from './pages/content/content.component';
import { RemoveModalComponent } from './components/remove-modal/remove-modal.component';
import { BreadcrumbModule } from './shared/modules/breadcrumb/breadcrumb.module';
import { LoaderModalComponent } from './components/loader-modal/loader-modal.component';
import { NavigationBarService } from './components/nav-bar/nav-bar.service';
import { RestApiService } from './shared/services/RestApiServices.service';
import { HelperService } from './shared/services/Helper.service';
import { VersionModalComponent } from './components/version-modal/version-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppConfigService } from './shared/services/app-config.service';
import { UsersService } from './pages/content/users/users.service';
import { CustomPipesModule } from './shared/pipes/custome-pipes.module';
import { AccountModalComponent } from './components/account-modal/account-modal.component';
import { FsRadioButtonModule } from './shared/modules/fs-radio-button/fs-radio-button.module';
import { CompaniesService } from './pages/content/companies/companies.service';
import { MiniLoaderModule } from './shared/modules/mini-loader/mini-loader.module';
import { AuthService } from './shared/services/AuthService';
import {LoggerService} from "./shared/services/logger.service";
import { CountryCodeService } from './shared/services/country-code.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavBarComponent,
    ContentComponent,
    RemoveModalComponent,
    LoaderModalComponent,
    VersionModalComponent,
    AccountModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BreadcrumbModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSortModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTabsModule,
    MatTreeModule,
    MatCardModule,
    MatExpansionModule,
    CustomPipesModule,
    FsRadioButtonModule,
    MiniLoaderModule
  ],
  providers: [
    AuthGuard,
    RestApiService,
    HttpInterceptorProviders,
    NavigationBarService,
    HelperService,
    AppConfigService,
    UsersService,
    CompaniesService,
    LoggerService,
    AuthService,
    CountryCodeService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
